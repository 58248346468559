import React, { createContext } from 'react';

// Define the shape of the plan
export interface Plan {
  name: string;
  featured?: boolean;
  price: { Monthly: string; Yearly: string; YearlyTotal: string };
  firstMonthMonthlyPrice: string;
  planIDs: string[];
}

// Define the shape of the context
interface UpgradeContextType {
  selectedPlan: Plan | null;
  setSelectedPlan: (plan: Plan | null) => void;
  // Add more shared data as needed, e.g., userProfile
}

// Create the context
export const UpgradeContext = createContext<UpgradeContextType | undefined>(
  undefined,
);
