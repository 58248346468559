import urlJoin from 'url-join';
import MD5 from 'crypto-js/md5';

export const getURL = () => {
  let url =
    process.env.NEXT_PUBLIC_SITE_URL ?? // Set this to your site URL in production env.
    process.env.NEXT_PUBLIC_VERCEL_URL ?? // Automatically set by Vercel.
    'http://localhost:3002/';
  // Make sure to include `https://` when not localhost.
  url = url.startsWith('http') ? url : `https://${url}`;
  // Make sure to including trailing `/`.
  url = url.charAt(url.length - 1) === '/' ? url : `${url}/`;
  return url;
};

export const toSiteURL = (path: string) => {
  const url = getURL();
  return urlJoin(url, path);
};

export const toDateTime = (secs: number) => {
  const t = new Date('1970-01-01T00:30:00Z'); // Unix epoch start.
  t.setSeconds(secs);
  return t;
};

export const getUserAvatarUrl = ({
  email,
  profileAvatarUrl,
}: {
  email: string;
  profileAvatarUrl?: string;
}) => {
  const placeholderAvatarUrl = `https://www.gravatar.com/avatar/00000000000000000000000000000000?d=mp`;
  const fallbackAvatarUrl = `https://www.gravatar.com/avatar/${MD5(
    email
  )}?d=mp`;
  const isProfileAvatarUrlValid =
    profileAvatarUrl && profileAvatarUrl.length > 0;
  return isProfileAvatarUrlValid
    ? profileAvatarUrl
    : fallbackAvatarUrl ?? placeholderAvatarUrl;
};

//TODO: Fix for multiple Chargebee accounts
export const chargebeeAdminSettingsForSite = (site: string): { site: string, api_key: string } => {
  return site == 'creditcaptain'
    ? { site: process.env.NEXT_PUBLIC_CHARGEBEE_SITE, api_key: process.env.CHARGEBEE_API_KEY }
    : { site: process.env.NEXT_PUBLIC_CHARGEBEE_B_SITE, api_key: process.env.CHARGEBEE_B_API_KEY};
} 
export const chargebeeFrontendSettingsForSite = (site: string): { site: string, publishableKey: string } => {
  return site == 'creditcaptain'
    ? { site: process.env.NEXT_PUBLIC_CHARGEBEE_SITE, publishableKey: process.env.NEXT_PUBLIC_CHARGEBEE_API_KEY }
    : { site: process.env.NEXT_PUBLIC_CHARGEBEE_B_SITE, publishableKey: process.env.NEXT_PUBLIC_CHARGEBEE_B_API_KEY};
} 

export const encodeKey = "ZXCVB", xorEncodeDecode = (input:string) => [...input].map((c, i) => String.fromCharCode(c.charCodeAt(0) ^ encodeKey.charCodeAt(i % encodeKey.length))).join('');
export const chargebeeToUse = async (): Promise<string> => {
  const response = await fetch('/api/egu');
  const data = await response.json();
  const gateway = xorEncodeDecode(data.e); // 'e' is the secret property key for "gateway"
  return gateway;
}