export interface Discount {
    percentage: number
    description: string
}

export const DISCOUNT_CODES: Record<string, Discount> = {
    CAPTAIN100: {
        percentage: 100,
        description: "100% off all plans"
    }
}
