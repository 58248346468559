import { DashedDivider6 } from '@/components/ui/divider-dashed';
import IconCreditCaptain from '@public/icons/credit-captain.svg';
import IconCircleCheck from '@public/icons/circle-check.svg';
import { ButtonPrimary } from '@/components/ui/button';
import Image from 'next/image';
import { useContext, useState, useEffect } from 'react';
import { UpgradeContext } from './UpgradeContext';
import { plans } from '@/constants';
import { DISCOUNT_CODES } from '@/lib/discounts';
import Cookies from 'js-cookie';

interface PageChoosePlanProps {
  hideHeader?: boolean;
}

export default function PageChoosePlan({ hideHeader = false }: PageChoosePlanProps) {
  const { selectedPlan, setSelectedPlan } = useContext(UpgradeContext) ?? {};
  const [activeTab, setActiveTab] = useState(selectedPlan?.name || 'Basic');
  const [activeDiscount, setActiveDiscount] = useState<number>(0);
  
  if (!setSelectedPlan) {
    throw new Error('PageChoosePlan must be used within an UpgradeContext.Provider');
  }
  
  // Check for discount code in cookie
  useEffect(() => {
    const couponCode = Cookies.get("creditcaptain_coupon");
    if (couponCode && DISCOUNT_CODES[couponCode]) {
      setActiveDiscount(DISCOUNT_CODES[couponCode].percentage);
    }
  }, []);
  
  // Sync activeTab with selectedPlan when it changes
  useEffect(() => {
    if (selectedPlan?.name) {
      setActiveTab(selectedPlan.name);
    }
  }, [selectedPlan]);
  
  // Handle tab selection (only changes the displayed tab)
  const handleTabSelect = (planName: string) => {
    setActiveTab(planName);
  };

  // Handle plan selection (sets the plan in context, triggering payment form)
  const handlePlanSelect = (planName: string) => {
    const plan = plans.find(p => p.name === planName);
    if (plan) {
      setSelectedPlan(plan);
    }
  };

  // Find plans by name for easier access
  const basicPlan = plans.find(p => p.name === 'Basic');
  const proPlan = plans.find(p => p.name === 'Pro');
  const turboPlan = plans.find(p => p.name === 'Turbo');

  // Calculate discount percentages
  const calculateDiscount = (monthly: string, yearly: string) => {
    const monthlyPrice = parseFloat(monthly);
    const yearlyPrice = parseFloat(yearly);
    if (monthlyPrice && yearlyPrice) {
      return Math.round(((monthlyPrice - yearlyPrice) / monthlyPrice) * 100);
    }
    return 0;
  };

  const calculateFirstMonthDiscount = (monthly: string, firstMonth: string) => {
    const monthlyPrice = parseFloat(monthly);
    const firstMonthPrice = parseFloat(firstMonth);
    if (monthlyPrice && firstMonthPrice) {
      return Math.round(((monthlyPrice - firstMonthPrice) / monthlyPrice) * 100);
    }
    return 0;
  };

  const basicDiscount = calculateDiscount(basicPlan?.price.Monthly || "0", basicPlan?.price.Yearly || "0");
  const proDiscount = calculateDiscount(proPlan?.price.Monthly || "0", proPlan?.price.Yearly || "0");
  const turboDiscount = calculateDiscount(turboPlan?.price.Monthly || "0", turboPlan?.price.Yearly || "0");
  
  const basicFirstMonthDiscount = calculateFirstMonthDiscount(basicPlan?.price.Monthly || "0", basicPlan?.firstMonthMonthlyPrice || "0");
  const proFirstMonthDiscount = calculateFirstMonthDiscount(proPlan?.price.Monthly || "0", proPlan?.firstMonthMonthlyPrice || "0");
  const turboFirstMonthDiscount = calculateFirstMonthDiscount(turboPlan?.price.Monthly || "0", turboPlan?.firstMonthMonthlyPrice || "0");

  // Calculate the discounted price based on the active discount
  const calculateDiscountedPrice = (price: string) => {
    if (!activeDiscount || activeDiscount <= 0) return price;
    
    const numericPrice = parseFloat(price);
    if (isNaN(numericPrice)) return price;
    
    const discountMultiplier = (100 - activeDiscount) / 100;
    const discountedPrice = (numericPrice * discountMultiplier).toFixed(2);
    
    return discountedPrice;
  };

  return (
    <div className='block justify-center w-full'>
      <div className='w-full max-w-[846px]'>
        {!hideHeader && (
          <>
            <Image className='size-8' src='/images/logo-flat.svg' alt='CreditCaptain Logo' width={32} height={32}/>

            <div className='mt-4 text-display-m text-neutral-800'>
              You're one step closer to a better score!
            </div>
            <p className='mt-1 mb-4 text-body-s text-neutral-600'>
              Get started for $0 today.
            </p>
          </>
        )}

        {/* Mobile Plan Tabs */}
        <div className="flex md:hidden mb-4 border-b border-neutral-200 mx-auto w-full">
          <button 
            className={`flex-1 py-3 text-sm font-medium ${activeTab === 'Basic' ? 'text-primary-normal border-b-2 border-primary-normal' : 'text-neutral-600'}`}
            onClick={() => handleTabSelect('Basic')}
          >
            Basic Plan
          </button>
          <button 
            className={`flex-1 py-3 text-sm font-medium ${activeTab === 'Pro' ? 'text-[#7C3AED] border-b-2 border-[#7C3AED]' : 'text-neutral-600'}`}
            onClick={() => handleTabSelect('Pro')}
          >
            Pro Plan
          </button>
          <button 
            className={`flex-1 py-3 text-sm font-medium ${activeTab === 'Turbo' ? 'text-[#0779FF] border-b-2 border-[#0779FF]' : 'text-neutral-600'}`}
            onClick={() => handleTabSelect('Turbo')}
          >
            Turbo Plan
          </button>
        </div>

        {/* Desktop Plans Grid */}
        <div className='hidden md:block lg:w-full'>
          <div className='overflow-x-auto pb-4'>
            <div className='inline-flex gap-4 py-1 min-w-max pl-px'>
              {/* Basic Plan */}
              <div className='relative flex flex-col gap-5 overflow-hidden rounded-[23px] bg-neutral-0 p-5 w-[270px] h-fit'
                style={{ boxShadow: '0px 0px 0px 1px #E0E0E0, 0px 2px 4px rgba(163, 163, 163, 0.08), inset 0px -0.5px 0.5px rgba(163, 163, 163, 0.12)' }}>
                <div>
                  <div className='flex size-10 items-center justify-center rounded-[11px] bg-primary-normal/[.12]'
                    style={{
                      boxShadow: '0px 0px 0px 0.833333px rgba(18, 160, 47, 0.24), inset 0px 0.416667px 0.416667px #FFFFFF'
                    }}>
                    <IconCreditCaptain className='size-5 text-primary-normal' viewBox='0 0 12 12' />
                  </div>
                  <div className='mt-4'>
                    <div className='text-heading-m text-neutral-900'>
                      <span className="flex items-center gap-2">
                        Basic Plan
                        {activeDiscount > 0 && (
                          <span className="relative whitespace-nowrap rounded-full px-2 py-0.5 text-xs font-semibold text-white">
                            <div
                              className="pointer-events-none absolute inset-0 h-full w-full scale-100 transform-gpu blur-md"
                              style={{
                                willChange: "transform",
                                backfaceVisibility: "hidden",
                                background:
                                  "linear-gradient(to right, rgb(8, 148, 255), rgb(201, 89, 221), rgb(255, 46, 84), rgb(255, 144, 4))"
                              }}
                            />
                            <span className="relative rounded-full bg-zinc-900 px-2.5 py-1 text-white">
                              {activeDiscount}% OFF
                            </span>
                          </span>
                        )}
                      </span>
                    </div>
                    <div className='flex items-center gap-2 mt-1'>
                      <div className='text-[36px]/[40px] text-neutral-900' style={{ fontWeight: 520 }}>
                        ${activeDiscount > 0 
                          ? calculateDiscountedPrice(basicPlan?.firstMonthMonthlyPrice || "0") 
                          : basicPlan?.firstMonthMonthlyPrice}
                      </div>
                      <div>
                        <div className='flex items-center gap-2'>
                          <div className='relative text-[16px]/[20px] font-medium -tracking-[0.006em] text-neutral-600 before:absolute before:left-0 before:top-1/2 before:h-px before:w-full before:-translate-y-1/2 before:bg-neutral-600'>
                            ${basicPlan?.price.Monthly}
                          </div>
                          {activeDiscount > 0 ? null : (
                            <div className='rounded-md bg-[#FBE7DA] px-1.5 py-0.5 text-caption-m text-[#C95203]'
                              style={{ boxShadow: '0px 0px 0px 1px rgba(226, 91, 0, 0.24), inset 0px 0.5px 0.5px #fff' }}
                            >
                              {basicFirstMonthDiscount}% OFF
                            </div>
                          )}
                        </div>
                        <div className='text-body-s text-neutral-600'>first month</div>
                      </div>
                    </div>
                    {/* <div className='mt-1 text-body-xs text-neutral-600'>
                      Then ${basicPlan?.price.Monthly}/mo or ${basicPlan?.price.Yearly}/mo yearly ({basicDiscount}% off)
                    </div> */}
                    <div className='mt-2 text-body-s text-neutral-700'>
                      Simple, automated credit fixes to get you back on track.
                    </div>
                  </div>
                </div>

                <DashedDivider6 className='text-[#153C1D]/[.16]' />

                <div>
                  <div className='text-action text-neutral-700'>What's included:</div>
                  <ul className='flex flex-col gap-2 mt-2 text-body-s text-neutral-700'>
                    <li className='flex items-center gap-2'>
                      <IconCircleCheck className='size-4 shrink-0 text-neutral-400' />
                      Aggressiveness: <span className='font-semibold'>Medium</span>
                    </li>
                    <li className='flex items-center gap-2'>
                      <IconCircleCheck className='size-4 shrink-0 text-neutral-400' />
                      AI-Powered Credit Disputes
                    </li>
                    <li className='flex items-center gap-2'>
                      <IconCircleCheck className='size-4 shrink-0 text-neutral-400' />
                      Live Score Dashboard
                    </li>
                    <li className='flex items-center gap-2'>
                      <IconCircleCheck className='size-4 shrink-0 text-neutral-400' />
                      Score Analysis
                    </li>
                    <li className='flex items-center gap-2'>
                      <IconCircleCheck className='size-4 shrink-0 text-neutral-400' />
                      $1M in ID theft Insurance
                    </li>
                  </ul>
                </div>

                <ButtonPrimary
                  className='w-full'
                  onClick={() => handlePlanSelect('Basic')}>
                  Select Basic
                </ButtonPrimary>
              </div>

              {/* Pro Plan */}
              <div className='relative w-[270px]'>
                <div className='absolute -top-6 left-1/2 flex h-6 -translate-x-1/2 items-center whitespace-nowrap rounded-t-xl bg-[#7C3AED]/[.12] px-4 text-xs font-semibold -tracking-[0.0106em] text-[#7C3AED]'>
                  BEST VALUE
                </div>
                <div
                  className='relative isolate flex flex-col gap-5 overflow-hidden rounded-[23px] p-5 ring-1 ring-inset ring-[#300A70]/[.12]'
                  style={{
                    boxShadow: 'inset 0px -0.5px 0.5px rgba(163, 163, 163, 0.12)',
                    background: 'linear-gradient(180deg, rgba(168, 124, 243, 0) 60%, rgba(168, 124, 243, .25) 70%, rgba(168, 124, 243, .7) 80%, #F9F6FE 101.37%), linear-gradient(174.16deg, #180538 4.64%, #5011BB 40.93%, #7C3AED 81.75%, #C1A2F6 95.36%), #300A70'
                  }}>
                  <Image
                    src='/images/pro-plan-bg-shape.svg'
                    alt=''
                    className='absolute top-0 left-0 object-contain w-full pointer-events-none'
                    width={100}
                    height={100}
                    layout='responsive'
                  />

                  <div>
                    <div
                      className='flex size-10 items-center justify-center rounded-[11px] bg-white/[.04]'
                      style={{
                        boxShadow: '0px 0px 0px 0.83px rgba(255, 255, 255, 0.4), inset 0px 0.41px 0.41px #fff'
                      }}>
                      <IconCreditCaptain className='size-5 text-neutral-0' viewBox='0 0 12 12' />
                    </div>
                    <div className='mt-4'>
                      <div className='text-heading-m text-neutral-0'>
                        <span className="flex items-center gap-2">
                          Pro Plan
                          {activeDiscount > 0 && (
                            <span className="relative whitespace-nowrap rounded-full px-2 py-0.5 text-xs font-semibold text-white">
                              <div
                                className="pointer-events-none absolute inset-0 h-full w-full scale-100 transform-gpu blur-md"
                                style={{
                                  willChange: "transform",
                                  backfaceVisibility: "hidden",
                                  background:
                                    "linear-gradient(to right, rgb(8, 148, 255), rgb(201, 89, 221), rgb(255, 46, 84), rgb(255, 144, 4))"
                                }}
                              />
                              <span className="relative rounded-full bg-zinc-900 px-2.5 py-1 text-white">
                                {activeDiscount}% OFF
                              </span>
                            </span>
                          )}
                        </span>
                      </div>
                      <div className='flex items-center gap-2 mt-1'>
                        <div className='text-[36px]/[40px] text-neutral-0' style={{ fontWeight: 520 }}>
                          ${activeDiscount > 0 
                            ? calculateDiscountedPrice(proPlan?.firstMonthMonthlyPrice || "0") 
                            : proPlan?.firstMonthMonthlyPrice}
                        </div>
                        <div>
                          <div className='flex items-center gap-2'>
                            <div className='relative text-[16px]/[20px] font-medium -tracking-[0.006em] text-neutral-0 before:absolute before:left-0 before:top-1/2 before:h-px before:w-full before:-translate-y-1/2 before:bg-neutral-0'>
                              ${proPlan?.price.Monthly}
                            </div>
                            {activeDiscount > 0 ? null : (
                              <span className="text-xs font-medium bg-[#F5F0FE] text-[#7C3AED] px-1.5 py-0.5 rounded border border-[#7C3AED]/20">{proFirstMonthDiscount}% OFF</span>
                            )}
                          </div>
                          <div className='text-body-s text-neutral-0'>first month</div>
                        </div>
                      </div>
                      {/* <div className='mt-1 text-body-xs text-neutral-0'>
                        Then ${proPlan?.price.Monthly}/mo or ${proPlan?.price.Yearly}/mo yearly ({proDiscount}% off)
                      </div> */}
                      <div className='mt-2 text-body-s text-neutral-0'>
                        Enhanced AI-powered credit repair with faster results.
                      </div>
                    </div>
                  </div>

                  <DashedDivider6 className='text-white/[.16]' />

                  <div>
                    <div className='text-action text-neutral-0'>What's included:</div>
                    <ul className='flex flex-col gap-2 mt-2 text-body-s text-neutral-0'>
                      <li className='flex items-center gap-2'>
                        <IconCircleCheck className='size-4 shrink-0 text-neutral-0' />
                        Aggressiveness: <span className='font-semibold'>Medium</span>
                      </li>
                      <li className='flex items-center gap-2'>
                        <IconCircleCheck className='size-4 shrink-0 text-neutral-0' />
                        AI-Powered Credit Disputes
                      </li>
                      <li className='flex items-center gap-2'>
                        <IconCircleCheck className='size-4 shrink-0 text-neutral-0' />
                        Live Score Dashboard
                      </li>
                      <li className='flex items-center gap-2'>
                        <IconCircleCheck className='size-4 shrink-0 text-neutral-0' />
                        Score Analysis
                      </li>
                      <li className='flex items-center gap-2'>
                        <IconCircleCheck className='size-4 shrink-0 text-neutral-0' />
                        $1M in ID theft Insurance
                      </li>
                      <li className='flex items-center gap-2'>
                        <IconCircleCheck className='size-4 shrink-0 text-neutral-0' />
                        AI Credit score monitoring
                      </li>
                      <li className='flex items-center gap-2'>
                        <IconCircleCheck className='size-4 shrink-0 text-neutral-0' />
                        AI Goal Oriented Growth
                      </li>
                      <li className='flex items-center gap-2'>
                        <IconCircleCheck className='size-4 shrink-0 text-neutral-0' />
                        CreditCaptain Boost™
                      </li>
                    </ul>
                  </div>

                  <ButtonPrimary
                    className='w-full'
                    color='purple'
                    onClick={() => handlePlanSelect('Pro')}>
                    Select Pro
                  </ButtonPrimary>
                </div>
              </div>

              {/* Turbo Plan */}
              <div
                className='relative flex flex-col gap-5 overflow-hidden rounded-[23px] bg-neutral-0 p-5 w-[270px]'
                style={{
                  boxShadow: '0px 0px 0px 1px #E0E0E0, 0px 2px 4px rgba(163, 163, 163, 0.08), inset 0px -0.5px 0.5px rgba(163, 163, 163, 0.12)'
                }}>
                <div>
                  <div className='flex size-10 items-center justify-center rounded-[11px] bg-[#3A8EED]/[.12]'
                    style={{
                      boxShadow: '0px 0px 0px 0.833333px rgba(58, 142, 237, 0.32), inset 0px 0.416667px 0.416667px #FFFFFF'
                    }}>
                    <IconCreditCaptain className='size-5 text-[#0779FF]' viewBox='0 0 12 12' />
                  </div>
                  <div className='mt-4'>
                    <div className='text-heading-m text-neutral-900'>
                      <span className="flex items-center gap-2">
                        Turbo Plan
                        {activeDiscount > 0 && (
                          <span className="relative whitespace-nowrap rounded-full px-2 py-0.5 text-xs font-semibold text-white">
                            <div
                              className="pointer-events-none absolute inset-0 h-full w-full scale-100 transform-gpu blur-md"
                              style={{
                                willChange: "transform",
                                backfaceVisibility: "hidden",
                                background:
                                  "linear-gradient(to right, rgb(8, 148, 255), rgb(201, 89, 221), rgb(255, 46, 84), rgb(255, 144, 4))"
                              }}
                            />
                            <span className="relative rounded-full bg-zinc-900 px-2.5 py-1 text-white">
                              {activeDiscount}% OFF
                            </span>
                          </span>
                        )}
                      </span>
                    </div>
                    <div className='flex items-center gap-2 mt-1'>
                      <div className='text-[36px]/[40px] text-neutral-900' style={{ fontWeight: 520 }}>
                        ${activeDiscount > 0 
                          ? calculateDiscountedPrice(turboPlan?.firstMonthMonthlyPrice || "0") 
                          : turboPlan?.firstMonthMonthlyPrice}
                      </div>
                      <div>
                        <div className='flex items-center gap-2'>
                          <div className='relative text-[16px]/[20px] font-medium -tracking-[0.006em] text-neutral-600 before:absolute before:left-0 before:top-1/2 before:h-px before:w-full before:-translate-y-1/2 before:bg-neutral-600'>
                            ${turboPlan?.price.Monthly}
                          </div>
                          {activeDiscount > 0 ? null : (
                            <div
                              className='rounded-md bg-[#EBF5FF] px-1.5 py-0.5 text-caption-m text-[#0779FF]'
                              style={{
                                boxShadow: '0px 0px 0px 1px rgba(7, 121, 255, 0.24), inset 0px 0.5px 0.5px #fff'
                              }}>
                              {turboFirstMonthDiscount}% OFF
                            </div>
                          )}
                        </div>
                        <div className='text-body-s text-neutral-600'>first month</div>
                      </div>
                    </div>
                    <div className='mt-2 text-body-s text-neutral-700'>
                      Full-speed credit recovery with premium AI and personal support.
                    </div>
                  </div>
                </div>

                <DashedDivider6 className='text-[#153C1D]/[.16]' />

                <div>
                  <div className='text-action text-neutral-700'>What's included:</div>
                  <ul className='flex flex-col gap-2 mt-2 text-body-s text-neutral-700'>
                    <li className='flex items-center gap-2'>
                      <IconCircleCheck className='size-4 shrink-0 text-neutral-400' />
                      Aggressiveness: <span className='font-semibold'>Very High</span>
                    </li>
                    <li className='flex items-center gap-2'>
                      <IconCircleCheck className='size-4 shrink-0 text-neutral-400' />
                      AI-Powered Credit Disputes
                    </li>
                    <li className='flex items-center gap-2'>
                      <IconCircleCheck className='size-4 shrink-0 text-neutral-400' />
                      Live Score Dashboard
                    </li>
                    <li className='flex items-center gap-2'>
                      <IconCircleCheck className='size-4 shrink-0 text-neutral-400' />
                      Score Analysis
                    </li>
                    <li className='flex items-center gap-2'>
                      <IconCircleCheck className='size-4 shrink-0 text-neutral-400' />
                      $1M in ID theft Insurance
                    </li>
                    <li className='flex items-center gap-2'>
                      <IconCircleCheck className='size-4 shrink-0 text-neutral-400' />
                      AI Credit score monitoring
                    </li>
                    <li className='flex items-center gap-2'>
                      <IconCircleCheck className='size-4 shrink-0 text-neutral-400' />
                      AI Goal Oriented Growth
                    </li>
                    <li className='flex items-center gap-2'>
                      <IconCircleCheck className='size-4 shrink-0 text-neutral-400' />
                      CreditCaptain Boost™
                    </li>
                    <li className='flex items-center gap-2'>
                      <IconCircleCheck className='size-4 shrink-0 text-neutral-400' />
                      Dedicated Account Manager
                    </li>
                    <li className='flex items-center gap-2'>
                      <IconCircleCheck className='size-4 shrink-0 text-neutral-400' />
                      Financing Offers
                    </li>
                  </ul>
                </div>

                <ButtonPrimary
                  className='w-full'
                  color='blue'
                  onClick={() => handlePlanSelect('Turbo')}>
                  Select Turbo
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
        
        {/* Mobile Plan Content */}
        <div className="md:hidden w-full">
          {activeTab === 'Basic' && (
            <div className="border border-neutral-200 rounded-2xl p-6">
              <div className="flex items-start gap-3">
                <div className='flex size-10 items-center justify-center rounded-[11px] bg-primary-normal/[.12]'
                  style={{
                    boxShadow: '0px 0px 0px 0.833333px rgba(18, 160, 47, 0.24), inset 0px 0.416667px 0.416667px #FFFFFF'
                  }}>
                  <IconCreditCaptain className='size-5 text-primary-normal' viewBox='0 0 12 12' />
                </div>
                <div>
                  <div className='text-heading-s text-neutral-900'>
                    <span className="flex items-center gap-2">
                      Basic Plan
                      {activeDiscount > 0 && (
                        <span className="relative whitespace-nowrap rounded-full px-2 py-0.5 text-xs font-semibold text-white">
                          <div
                            className="pointer-events-none absolute inset-0 h-full w-full scale-100 transform-gpu blur-md"
                            style={{
                              willChange: "transform",
                              backfaceVisibility: "hidden",
                              background:
                                "linear-gradient(to right, rgb(8, 148, 255), rgb(201, 89, 221), rgb(255, 46, 84), rgb(255, 144, 4))"
                            }}
                          />
                          <span className="relative rounded-full bg-zinc-900 px-2.5 py-1 text-white">
                            {activeDiscount}% OFF
                          </span>
                        </span>
                      )}
                    </span>
                  </div>
                  <div className='flex items-center gap-2'>
                    <div className='text-2xl font-medium text-neutral-900'>${activeDiscount > 0 
                      ? calculateDiscountedPrice(basicPlan?.firstMonthMonthlyPrice || "0") 
                      : basicPlan?.firstMonthMonthlyPrice}</div>
                    <span className="line-through text-sm text-neutral-600">${basicPlan?.price.Monthly}</span>
                    {activeDiscount > 0 ? null : (
                      <span className="text-xs font-medium bg-[#FBE7DA] text-[#C95203] px-1.5 py-0.5 rounded">{basicFirstMonthDiscount}% OFF</span>
                    )}
                  </div>
                  <div className="text-xs text-neutral-600">first month</div>
                  {/* <div className="text-xs text-neutral-600 mt-1">
                    Then ${basicPlan?.price.Monthly}/mo
                  </div> */}
                </div>
              </div>
              
              <div className="mt-6">
                <div className='text-sm font-medium text-neutral-700'>What's included:</div>
                <ul className='flex flex-col gap-2 mt-2 text-sm text-neutral-700'>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-neutral-400' />
                    Aggressiveness: <span className='font-semibold'>Medium</span>
                  </li>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-neutral-400' />
                    AI-Powered Credit Disputes
                  </li>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-neutral-400' />
                    Score Analysis
                  </li>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-neutral-400' />
                    Live Score Dashboard
                  </li>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-neutral-400' />
                    $1M in ID theft Insurance
                  </li>
                </ul>
              </div>
              
              <div className="mt-4">
                <ButtonPrimary
                  className='w-full'
                  onClick={() => handlePlanSelect('Basic')}>
                  Select Basic
                </ButtonPrimary>
              </div>
            </div>
          )}
          
          {activeTab === 'Pro' && (
            <div className="border border-[#7C3AED]/30 rounded-2xl p-6 bg-gradient-to-b from-[#F9F6FE] to-[#F5F0FE]">
              <div className="flex items-start gap-3">
                <div className='flex size-10 items-center justify-center rounded-[11px] bg-[#7C3AED]/[.12]'
                  style={{
                    boxShadow: '0px 0px 0px 0.833333px rgba(124, 58, 237, 0.32), inset 0px 0.416667px 0.416667px #FFFFFF'
                  }}>
                  <IconCreditCaptain className='size-5 text-[#7C3AED]' viewBox='0 0 12 12' />
                </div>
                <div>
                  <div className='text-heading-s text-neutral-900'>
                    <span className="flex items-center gap-2">
                      Pro Plan
                      {activeDiscount > 0 && (
                        <span className="relative whitespace-nowrap rounded-full px-2 py-0.5 text-xs font-semibold text-white">
                          <div
                            className="pointer-events-none absolute inset-0 h-full w-full scale-100 transform-gpu blur-md"
                            style={{
                              willChange: "transform",
                              backfaceVisibility: "hidden",
                              background:
                                "linear-gradient(to right, rgb(8, 148, 255), rgb(201, 89, 221), rgb(255, 46, 84), rgb(255, 144, 4))"
                            }}
                          />
                          <span className="relative rounded-full bg-zinc-900 px-2.5 py-1 text-white">
                            {activeDiscount}% OFF
                          </span>
                        </span>
                      )}
                    </span>
                  </div>
                  <div className='flex items-center gap-2'>
                    <div className='text-2xl font-medium text-neutral-900'>${activeDiscount > 0 
                      ? calculateDiscountedPrice(proPlan?.firstMonthMonthlyPrice || "0") 
                      : proPlan?.firstMonthMonthlyPrice}</div>
                    <span className="line-through text-sm text-neutral-600">${proPlan?.price.Monthly}</span>
                    {activeDiscount > 0 ? null : (
                      <span className="text-xs font-medium bg-[#F5F0FE] text-[#7C3AED] px-1.5 py-0.5 rounded border border-[#7C3AED]/20">{proFirstMonthDiscount}% OFF</span>
                    )}
                  </div>
                  <div className="text-xs text-neutral-600">first month</div>
                  {/* <div className="text-xs text-neutral-600 mt-1">
                    Then ${proPlan?.price.Monthly}/mo
                  </div> */}
                </div>
              </div>
              
              <div className="mt-6">
                <div className='text-sm font-medium text-neutral-700'>What's included:</div>
                <ul className='flex flex-col gap-2 mt-2 text-sm text-neutral-700'>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-[#7C3AED]' />
                    Aggressiveness: <span className='font-semibold'>High</span>
                  </li>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-[#7C3AED]' />
                    AI-Powered Credit Disputes
                  </li>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-[#7C3AED]' />
                    Score Analysis
                  </li>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-[#7C3AED]' />
                    Live Score Dashboard
                  </li>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-[#7C3AED]' />
                    $1M in ID theft Insurance
                  </li>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-[#7C3AED]' />
                    AI Credit score monitoring
                  </li>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-[#7C3AED]' />
                    AI Goal Oriented Growth
                  </li>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-[#7C3AED]' />
                    CreditCaptain Boost™
                  </li>
                </ul>
              </div>
              
              <div className="mt-4">
                <ButtonPrimary
                  className='w-full'
                  color='purple'
                  onClick={() => handlePlanSelect('Pro')}>
                  Select Pro
                </ButtonPrimary>
              </div>
            </div>
          )}
          
          {activeTab === 'Turbo' && (
            <div className="border border-[#0779FF]/30 rounded-2xl p-6 bg-gradient-to-b from-[#F5F9FE] to-[#EBF5FF]">
              <div className="flex items-start gap-3">
                <div className='flex size-10 items-center justify-center rounded-[11px] bg-[#0779FF]/[.12]'
                  style={{
                    boxShadow: '0px 0px 0px 0.833333px rgba(7, 121, 255, 0.32), inset 0px 0.416667px 0.416667px #FFFFFF'
                  }}>
                  <IconCreditCaptain className='size-5 text-[#0779FF]' viewBox='0 0 12 12' />
                </div>
                <div>
                  <div className='text-heading-s text-neutral-900'>
                    <span className="flex items-center gap-2">
                      Turbo Plan
                      {activeDiscount > 0 && (
                        <span className="relative whitespace-nowrap rounded-full px-2 py-0.5 text-xs font-semibold text-white">
                          <div
                            className="pointer-events-none absolute inset-0 h-full w-full scale-100 transform-gpu blur-md"
                            style={{
                              willChange: "transform",
                              backfaceVisibility: "hidden",
                              background:
                                "linear-gradient(to right, rgb(8, 148, 255), rgb(201, 89, 221), rgb(255, 46, 84), rgb(255, 144, 4))"
                            }}
                          />
                          <span className="relative rounded-full bg-zinc-900 px-2.5 py-1 text-white">
                            {activeDiscount}% OFF
                          </span>
                        </span>
                      )}
                    </span>
                  </div>
                  <div className='flex items-center gap-2'>
                    <div className='text-2xl font-medium text-neutral-900'>${activeDiscount > 0 
                      ? calculateDiscountedPrice(turboPlan?.firstMonthMonthlyPrice || "0") 
                      : turboPlan?.firstMonthMonthlyPrice}</div>
                    <span className="line-through text-sm text-neutral-600">${turboPlan?.price.Monthly}</span>
                    {activeDiscount > 0 ? null : (
                      <span className="text-xs font-medium bg-[#EBF5FF] text-[#0779FF] px-1.5 py-0.5 rounded border border-[#0779FF]/20">{turboFirstMonthDiscount}% OFF</span>
                    )}
                  </div>
                  <div className="text-xs text-neutral-600">first month</div>
                </div>
              </div>
              
              <div className="mt-6">
                <div className='text-sm font-medium text-neutral-700'>What's included:</div>
                <ul className='flex flex-col gap-2 mt-2 text-sm text-neutral-700'>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-[#0779FF]' />
                    Aggressiveness: <span className='font-semibold'>Very High</span>
                  </li>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-[#0779FF]' />
                    AI-Powered Credit Disputes
                  </li>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-[#0779FF]' />
                    Score Analysis
                  </li>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-[#0779FF]' />
                    Live Score Dashboard
                  </li>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-[#0779FF]' />
                    $1M in ID theft Insurance
                  </li>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-[#0779FF]' />
                    AI Credit score monitoring
                  </li>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-[#0779FF]' />
                    AI Goal Oriented Growth
                  </li>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-[#0779FF]' />
                    CreditCaptain Boost™
                  </li>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-[#0779FF]' />
                    Dedicated Account Manager
                  </li>
                  <li className='flex items-center gap-2'>
                    <IconCircleCheck className='size-4 shrink-0 text-[#0779FF]' />
                    Financing Offers
                  </li>
                </ul>
              </div>
              
              <div className="mt-4">
                <ButtonPrimary
                  className='w-full'
                  color='blue'
                  onClick={() => handlePlanSelect('Turbo')}>
                  Select Turbo
                </ButtonPrimary>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}


