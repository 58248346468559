import { twMerge } from 'tailwind-merge';
import { clsx, ClassValue } from 'clsx';
import { selectedCountries } from '@/app/(auth)/sign-up/countries';
import { getPhoneCode, type CountryCode } from 'libphonenumber-js';
import countryNames from '@/utils/country-names.json';

export function classNames(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
export const cn = classNames; // Alias for classNames

// Phone number utils
export const countriesWithPhoneAndName = selectedCountries.reduce(
  (acc, code) => {
    acc[code] = {
      phoneCode: getPhoneCode(code),
      countryName: countryNames[code],
    };
    return acc;
  },
  {} as Record<CountryCode, Record<string, string>>,
);

export type CountryData = {
  phoneCode: string;
  countryName: string;
};
